
 .mainWrapper{width: 100%; background: #03989e; height: auto}
 .min_heightcstm{
   min-height: 100vh;
 }
 .custom-card {
   padding: 15px 25px 45px;
   box-shadow: 0px 1px 5px #0000003d;
   border-radius: 22px;
   background: #fff;}

   .logoMain{
   width: 100px;
   height: 100px;
   background: aliceblue;
   margin: 15px auto;
   text-align: center;
   line-height: 100px;
   border-radius: 50%;
}
 .headingTop h2{font-size: 30px;
   text-align: center;
   /* width: 190px; */
   margin: 0 auto;
   font-weight: bold;}

.headingTop p{font-size: 14px; color: #64626a; text-align: center; padding: 15px 0 0 0 ;}
.custombtns{display: grid;}
.custombtns2{display: flex; flex-wrap: wrap;}
.customButton-2{
   background: #5bdbd1;
   border: 0;
   margin: 10px 25px 10px 0;
   padding: 15px;
   border-radius: 8px;
   color: #fff;
   font-size: 30px;
   font-weight: bold;
   width: 45%;
}
.customButton{
   background: #5bdbd1;
   border: 0;
   margin: 10px 0;
   padding: 15px;
   border-radius: 8px;
   color: #fff;
   font-size: 30px;
   font-weight: bold;
}

button:focus {
   outline: 0;
    outline: 0; 
}
.thankyoucontent p{font-size: 14px; color: #64626a; text-align: center; margin-bottom: 0; line-height: 23px;}
.CustomPrice{background: #5bdbd1;
 width: 110px;
 margin: 20px auto;
   border: 0;
   padding: 5px;
   border-radius: 8px;
   color: #fff;
   font-size: 30px;
   font-weight: bold;
   text-align: center;}

.CustomPaybtn{padding: 10px;
   box-shadow: 0px 5px 5px #00000069;
   border-radius: 8px;
   background: #fff;
   width: 200px;
   text-align: center;
   border: 0; font-size: 20px;}

   .CustomBlue{padding: 10px;
   box-shadow: 0px 5px 5px #00000069;
   border-radius: 8px;
   background: #10afdd;
   width: 200px;
   text-align: center;
   border: 0; font-size: 20px; color: #fff;}

   .CustomPaybtn svg{
   width: 20px;
   height: 20px;
}
.marg-1{
width:50%
}

.SuccessIcon{
   width: 100px;
   height: 100px;
   background: #5bdbd1;
   margin: 15px auto;
   text-align: center;
   line-height: 100px;
   border-radius: 50%;}

   .SuccessIcon svg{color: #fff; width: 50px;
   height: 50px;}
  
   .custom-for-all {
     position: relative;
     z-index: 1000;
     /* margin-bottom: 50px!important; */
 }
   .d-justify-wrap{
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
   }
   body {
     min-height: 100%;
 }
 html {
   min-height: 100%;
}
footer.text-center.text-lg-start.text-dark {
 z-index: 4;
 position: absolute;
 bottom: 0px;
 width: 100%;
}
.card-custom2{
 min-height: 100vh;
 display: flex;
justify-content: center;
align-items: flex-start;
}
.inner-card {
 width: 100%;
 max-width: 450px;
 margin: 0px 15px;
}
.inner-card-invalidQr{
   width: 100%;
   max-width: 800px;
   margin: 0px 15px;
}
@media only screen and (max-width: 768px) {
 .inner-card {
   width: 100%;
   max-width: 90%;
   /* margin: 0px 15px; */
   margin: auto;
}
}
@media only screen and (max-width: 400px) {
 .button-section-1 {
   width: 100%;
 }
 .boxElement_btn.btnWidth {
   min-width: 100%;
   margin: 0 auto;
   width: 100%;
}
}
.input-icon2 {
 position: absolute;
 top: 20px;
}

.input-icon2 > i {
 position: absolute;
 display: block;
 transform: translate(0, -50%);
 top: 50%;
 pointer-events: none;
 width: 25px;
 text-align: center;
   font-style: normal;
}

.input-icon2 > input {
 padding-left: 25px;
   padding-right: 0;
}

.input-icon3 {
 position: absolute;
 top: 20px;
 right: 40px;
}

.input-icon3 > i {
 position: absolute;
 display: block;
 transform: translate(0, -50%);
 top: 50%;
 pointer-events: none;
 width: 25px;
 text-align: center;
   font-style: normal;
}

.input-icon3 > input {
 padding-left: 25px;
   padding-right: 0;
}


.lotteryFormBox .custom-formGroup label {
   background: #17dad1;
   height: 80px;
   display: flex;
   align-items: center;
   font-size: 15px;
   font-weight: 600;
   color: #fff;
   padding: 10px;
   border-radius: 5px;
   box-shadow: 0px 1px 5px #0000003d;
   /* overflow-y: auto; */
}

.lotteryFormBox .custom-formGroup label::after {
   content: '';
   display: none;
}

.lotteryFormBox .custom-formGroup input {
   height: 80px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8px 10px;
    margin: 1px 0;
}

.nextBTNBox button {
   background: #17dad1;
   padding: 5px 15px !important;
   border-radius: 5px;
   font-size: 16px;
   font-weight: 500;
   color: #fff;
}


@media screen and (max-width: 500px) {
   .lotteryFormBox .custom-formGroup label {      
      font-size: 12px;
      overflow: hidden;
  }
}


@media screen and (max-width: 384px) {
   /* .d-justify-wrap {
     flex-wrap: nowrap;
   } */
 }