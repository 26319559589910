body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  /*background: rgb(3, 152, 158);
  background-image: linear-gradient(
    90deg,
    rgba(3, 152, 158, 1) 0%,
    rgba(3, 152, 158, 1) 100%
  ); /* fallback */
  background-repeat: no-repeat;
  position: relative;
}

.bottom-image {
  position: absolute;
  /* background-image: url("../components/assests/image/background/bottom.png"); */
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 157px;
  height: 34px;
  right: 0;
  bottom: 0;
}

.top {
  /* background-image: url("../components/assests/image/background/top.png"); */
  background-repeat: no-repeat;
  padding-bottom: 10px;
}

.page-heading {
  color: #fff;
  font-size: 30px;
  padding-top: 30px;
}

.col-lg-12.px-0 {
  z-index: 2;
}

.link-below {
  text-transform: uppercase;
  color: #0d2a4d;
  font-weight: bold;
  border-bottom: 2px solid #0d2a4d;
}

.link-next {
  color: #0d2a4d;
  font-size: 18px;
}

.link-next:hover {
  text-decoration: none;
  color: #0d2a4d;
}
.editMailBtn {
  position: absolute;
    top: 0;
    border-radius: 5px;
    right: 11px;
    z-index: 2;
    border: none;
    top: 28px;
}
.editMailBtnLoc {
  position: absolute;
  border-radius: 5px;
  right: 11px;
  z-index: 2;
  border: none;
  top: 138px;
}
button.editMailBtnLoc.change_posibtnsc-4 {
  right: -4px;
  top: 31px;
  padding: 3.5px 4px;
}


.link-next:hover span {
  color: #fff;
}

.link-next span {
  text-transform: uppercase;
  color: #fff;
}

.content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  height: 60vh;
  /* background-image: url("../components/assests/image/background/center.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.content1 {
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  margin-top: 30px;
  height: 60vh;
  /* background-image: url("../components/assests/image/background/center.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #fff;
}

/* .btn {
  background: #fff;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  -moz-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  -webkit-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%;
  margin: 15px 10px; */
/* } */
*/ .btn22 {
  background: #fff;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  -moz-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  -webkit-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%;
  margin: 15px 10px; */
}
.btn11 {
  /* background: #fff; */

  width: 150px;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.focus,
.btn:focus {
  -moz-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  -webkit-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
  box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 10px #fff;
}

.logo {
  width: 100%;
  padding-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.logo-circle img {
  width: 84%;
  border-radius: 15%;
  padding: 5px;
}

.logo-text {
  color: #fff;
  font-size: 20px;
  padding-left: 1em;
}

.inner .content {
  height: 40vh;
  background-image: none;
  /* position: absolute; */
  /* left: 38%; */
  background-color: #fff;
  /* width: 300px; */
}
.inner .content1 {
  height: fit-content;
  background-image: none;
  position: absolute;
  left: 38%;
  background-color: #fff;
  width: 300px;
  position: absolute;
  left: 38%;
}

.inner .top {
  /* background-image: url("../components/assests/image/background/inner-top.png"); */
  background-repeat: no-repeat;
  padding-bottom: 10px;
  background-position: right;
  background-size: contain;
}

.inner .bottom {
  /* background-image: url("../components/assests/image/background/inner-bottom.png"); */
  background-repeat: no-repeat;
  background-position: left bottom;
}

.inner-bottom-image {
  position: absolute;
  /* background-image: url("../components/assests/image/background/inner-bottom.png"); */
  background-repeat: no-repeat;
  background-position: left bottom;
  width: 157px;
  height: 204px;
  left: 0;
  bottom: 0;
}
.downloadSpinText {
  color: white;
  font-size: 24px;
  padding: 20px;
}

#modelRetailerEventContainer1 {
  max-width: 700px !important;
}
.gApnFp {
  max-height: 679px !important;
}

@media screen and (max-width: 960px) {
  .content {
    flex-direction: column;
    height: calc(100vh - 200px);
    background-size: 130%;
  }
  .btn {
    height: 160px;
    width: 160px;
    -moz-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
    -webkit-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
    box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
  }
  .btn.focus,
  .btn:focus {
    -moz-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
    -webkit-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
    box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
  }
  .btn img {
    width: 85%;
  }

  .inner .content {
    flex-direction: column;
    height: calc(100vh - 360px);
    background-size: 130%;
  }
}

@media screen and (max-width: 960px) and (orientation: landscape) {
  .content {
    flex-direction: row;
    height: calc(100vh - 200px);
    background-size: contain;
  }
  .inner .content {
    flex-direction: row;
    height: calc(100vh - 200px);
    background-size: 130%;
    margin-top: 0;
  }
  .inner .page-heading {
    padding-top: 0px;
  }
  .logo-circle {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 640px) {
  .radioInputLottery {
    left: 15px !important;
    top: 48px !important;
  }
  .formgrpcustom {
    height: 75px !important;
  }
  .radioLable3Location {
    left: 36px !important;
    top: 66px !important;
  }
  .radioInputBoth {
    left: 15px !important;
    top: 71px !important;
  }
  .radioLableLotteryLocation {
    left: 36px !important;
    top: 43px !important;
  }
  .modalHeader h3 {
    font-size: 22px !important;
  }
  .radioLanLableAdd2 {
    left: 35px !important;
    top: 78px !important;
    width: 162px;
  }
  .radioLanInputAdd {
    left: 15px !important;
    top: 82px !important;
  }
  .lotteryTic {
    margin-left: 0px !important;
  }
  .lotteryFifty {
    margin-left: 0px !important;
  }
  .content {
    flex-direction: column;
    height: calc(100vh - 200px);
    background-size: 130%;
  }
  .button-section {
    margin-bottom: 1.5em;

    /* this class is not reflecting in browser */
  }
  .marg-1 {
    width: 40%;
    margin: 10px;
    display: block;
  }
  .btn {
    height: auto;
    width: 100%;
    box-shadow: none;
    max-width: 140px;
  }
  .btn.focus,
  .btn:focus {
    -moz-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
    -webkit-box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
    box-shadow: inset 0px 0px 0px 5px #00c2cb, 0px 0px 0px 5px #fff;
  }
  .btn img {
    width: 85%;
  }

  .logo-circle {
    width: 90px;
    height: 90px;
  }
  .logo-circle img {
    width: 80%;
  }
  .inner .content {
    height: 54vh;
  }
  .inner .page-heading {
    padding-top: 10px;
  }
}
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.width_Block {
  display: flex;
  justify-content: space-around;
}
.mt-btn-top {
  margin-top: 15px;
}
.selectedCharityWithAmount {
  display: block;
  margin-top: 20px;
  /* color: rgb(87, 87, 87); */
  color: "#00000";
}

.boxElement_btn {
  display: flex;
  justify-content: center;
  background-color: #17dad1;
  border-radius: 5px;
  color: #fff;
  height: 75px;
}
.boxElement_btn.btnWidth {
  width: 120px;
  box-shadow: 0px 5px 5px #00000069;
  /* Its for center */
  margin: 0px auto;
}
.boxElement_btn span {
  color: #fff;
  text-align: center;
}

/* 
.charity_logo {
  overflow: hidden;
  

} */
.custom-flex {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
.first-calender,
.second-calender {
  position: absolute;
  top: 22%;
  z-index: 999;
  margin: 0 !important;
  right: 40px;
}
.custom-flex.position-relative.align-items-start {
  justify-content: flex-start;
}
.custom-flex.position-relative.align-items-start a {
  min-width: 200px;
}
.button-main-cus button {
  width: 100%;
  display: inherit;
  background: #03989e;
  max-width: 300px;
  margin: 0 auto;
}

.button-main-cus button b {
  font-weight: normal;
  font-size: 16px;
}
.button-cus-ok {
  text-align: center;
  margin-bottom: 30px;
}
.custom-modal-head {
  text-align: center;
}

.custom-modal-head h4 {
  font-size: 24px !important;
  font-weight: bold !important;
  margin-bottom: 20px;
}

.custom-modal-head h6 {
  font-size: 18px !important;
  margin-bottom: 0;
  /* color: #b3afaf; */
  color: #000000;
}
.custom-modal-head h2 {
  font-size: 60px;
  margin-bottom: 20px;
  color: green;
  font-size: bolder;
}

.custom-modal-head {
  margin-top: 10px !important;
}
.button-cus-ok button {
  margin: 0 auto;
  min-width: 220px;
  padding: 10px !important;
  background: #47ccde;
  border: none;
}
.charityContainer .ModalBackground .modalContainer {
  max-width: 1900px !important;
  width: 100%;
  max-height: 103vh !important;
}
/* .charityContainer  .ModalBackground .modalContainer form {max-height: 99vh !important;} */
.Flex-input {
  display: flex;
  flex-direction: row;
}

.Flex-input input {
  margin-right: 10px !important;
}
.charityContainer .ModalBackground .modalContainer input {
  height: 37px;
  border-radius: 0;
  border: 1px solid #dedede;
}

.charityContainer .ModalBackground .modalContainer label {
  font-weight: 400;
  margin-bottom: 5px;
}
.charityContainer
  .ModalBackground
  .modalContainer
  .modalFooter.text-right.mt-5.pt-3 {
  display: flex;
  justify-content: end;
}
.retailerContainer
  .ModalBackground
  .modalContainer
  .modalFooter.text-right.mt-5.pt-3 {
  display: flex;
  justify-content: end;
}

.charityContainer .ModalBackground .modalContainer .modalHeader.mb-5 h3 {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
}
@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: pay; /* Use any supported button type. */
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}
@media only screen and (max-width: 767px) {
  .button-section-1 {
    width: fit-content !important;
  }
  .boxElement_btn.btnWidth{
    min-width:100px !important;
    box-shadow: 0px 5px 5px #00000069;

  }
  .ModalBackground button.editMailBtn,
  button.editMailBtnLoc.change_posibtnsc-4 {
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
  }
  .charityContainer .ModalBackground .modalContainer input {
    padding-left: 5px;
    font-size: 11px;
  }
  .inpcsbtdf input {
    font-size: 11px;
    padding-left: 5px;
  }
  .Flex-input {
    display: flex;
    flex-direction: column;
  }
  .charityContainer .ModalBackground .modalContainer {
    max-width: 553px !important;
    width: 100% !important;
  }
}
.bar_graph_name {
  font-size: 16px;
  font-weight: 400;
}

.input-icon {
  position: relative;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 25px;
  padding-right: 0;
}

.input-icon-right > i {
  right: 0;
}

.input-icon-right > input {
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
}
.processing-input-icon {
  position: relative;
}
.processing-input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
  right: 13px;
}
.processing-input-icon > input {
  padding-left: 25px;
  padding-right: 0;
}

.rdt_TableHeadRow {
  background: #e9ecef !important;
  color: #495057 !important;
  border-color: #dee2e6;
  padding: 0.75rem !important;
}
.MainDates {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
}
.custmmrg10 {
  margin-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  height: 25px;
}
.form-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ModalBackground .w80 {
  width: 80% !important;
  background: #fff;
  padding: 20px;
  position: relative;
}
.ModalBackground .w80 button.closeBtn {
  border: 0;
  font-size: 30px;
  background: transparent;
  position: absolute;
  right: 7px;
  top: 7px;
  line-height: 1;
}
#maxQRAlert button {
  padding: 6px 33px;
}
.modalBg {
  background: #ededed;
  padding: 10px 15px;
  border-radius: 10px;
}

.custmmb-5 {
  margin-bottom: 6rem;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 414px) {
  .ModalBackground .lotterymodalContainer {
    overflow: scroll !important;
  }
  form.cstmLog {
    margin-bottom: 10px !important;
    margin-top: 10px;
    padding: 6px 0 !important;
  }
  .processing-input-icon > i {
    top: -25px !important;
    right: 0px !important;
  }
  .modalFooter.text-right.mt-5.pt-3 button,
  .modalFooter:has(.updtBtn) button.updtBtn {
    width: 49% !important;
  }
  .charityContainer .ModalBackground .modalContainer .custom-file-label {
    font-size: 11px !important;
  }
  #updateCharityBackButton {
    width: 56% !important;
    margin: 0px 4px;
  }
  #updateCharityButton {
    width: 56% !important;
    margin: 0px 4px;
  }
  #updateRetailerBtn {
    padding: 4px 4px;
    width: 56% !important;
    margin: 0px 4px;
  }
  #updateRetailerCancelBtn {
    padding: 4px 4px;
    width: 56% !important;
    margin: 0px 4px;
  }
  .ModalBackground .modalContainer button.closeBtn {
    right: 5px !important;
    top: 15px !important;
  }
  #locationPercentIcon {
    top: -8px !important;
    right: 97px !important;
  }
  #updateLocationBackButton {
    width: 66% !important;
    margin: 0px 4px !important;
    padding: 5px 5px !important;
  }
  #updateLocationButton {
    width: 66% !important;
    margin: 0px 4px !important;
    padding: 5px 5px !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .modalFooter.text-right.mt-5.pt-3 button {
    width: 26% !important;
  }
  .lotterySelect {
    margin-bottom: 5px;
  }
  .downloadSpinText {
    font-size: 18px;
  }

  .retailerContainer .ModalBackground .RetailerPopupContainer {
    margin: 12px;
  }
  #exportModalBackground .modalContainer {
    margin: 12px !important;
  }
  .retailerContainer .ModalBackground .modelLocationContainer {
    margin: 12px !important;
    width: 93% !important;
  }
  #container-height {
    width: 95% !important;
    height: 90vh;
    max-width: 95% !important;
    overflow: scroll;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 700px) {
  .view-qr {
    margin: 0 !important;
    font-size: 9px !important;
  }
  #qr {
    display: flex;
    align-items: center;
  }
  .modalFooter.text-right.mt-5.pt-3 button {
    width: 26% !important;
  }
  form.cstmLog {
    margin-bottom: 10px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .Dashboardcontainer.p-3 {
    padding: 0 !important;
  }
  .recharts-wrapper {
    width: 100% !important;
  }
  .recharts-wrapper svg {
    width: 100% !important;
  }
  .recharts-legend-wrapper {
    width: 100% !important;
  }
  ul.recharts-default-legend {
    display: flex;
    flex-wrap: wrap;
    max-height: 150px;
    overflow-y: auto;
  }
  ul.recharts-default-legend li {
    width: 29.3%;
    font-size: 12px;
  }
  .MainDates {
    display: flex;
    margin-top: 55px;
    align-items: center;
    justify-content: center;
    margin-right: 60px;
  }
  .MainDates label {
    font-size: 0.8rem;
  }
  .donationSummaryModal button.btn.btn-primary {
    float: right !important;
  }
  .view-qr {
    margin: 0 !important;
  }
  #qr {
    display: flex;
    align-items: center;
  }

  .modalFooter button.btn {
    font-size: 15px !important;
    padding: 10px 20px;
  }
}
@media (max-width: 425px) {
  #locationPercentIcon {
    right: 15px !important;
    top: -27px !important;
    width: 1005;
  }
  .formgrpcustom {
    min-width: 219px !important;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .MainDates {
    width: 100%;
  }
  .form-main {
    display: initial;
  }
  .modalHeader h3 {
    font-size: 22px !important;
  }
  .modalFooter.text-right.mt-5.pt-3 button,
  .modalFooter:has(.updtBtn) button.updtBtn {
    width: 42% !important;
  }
  .modalFooter.text-right.mt-5.pt-3 .updateRetailerButton {
    width: 47% !important;
  }
  .custmmrg10 {
    margin-left: 0px;
    text-align: center;
    display: initial;
    margin-bottom: 55px;
  }
  .Dashboardcontainer.p-3 {
    padding: 0 !important;
  }
  .recharts-wrapper {
    width: 100% !important;
  }
  .recharts-legend-wrapper {
    width: 100% !important;
  }
  ul.recharts-default-legend {
    display: flex;
    flex-wrap: wrap;
    max-height: 150px;
    overflow-y: auto;
  }
  ul.recharts-default-legend li {
    width: 29.3%;
    font-size: 12px;
  }
  .MainDates {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
  }
  .MainDates label {
    font-size: 0.8rem;
  }
  .donationSummaryModal button.btn.btn-primary {
    float: right !important;
  }
  .view-qr {
    margin: 0 !important;
  }
  #qr {
    display: flex;
    align-items: center;
  }
  .ModalBackground .w80 {
    width: 95% !important;
  }
  .ModalBackground .lotterymodalContainer {
    height: 450px !important;
  }
}
footer.text-center.text-lg-start.text-dark {
  z-index: 0;
}
.custom-for-all {
  position: relative;
  z-index: 9;
  margin-bottom: 50px !important;
}

@media only screen and (max-width: 1750px) {
  .recharts-wrapper {
    width: 100% !important;
  }
  .recharts-wrapper .recharts-legend-wrapper {
    width: 100% !important;
  }
  .recharts-wrapper svg.recharts-surface {
    width: 100% !important;
  }
  table.table.table-striped tbody tr td {
    white-space: wrap;
    font-size: 0.8rem;
    word-break: break-all;
  }
}
@media (max-width: 1366px) {
  .all-inputspdngs input {
    padding-left: 20px !important;
  }
  table.table.table-striped tbody tr td {
    white-space: wrap;
    font-size: 0.7rem;
    word-break: break-all;
  }
  .charityContainer .ModalBackground .modalContainer {
    margin: 35px !important;
  }
  .customize-table thead tr th {
    white-space: unset !important;
    word-break: break-word;
  }
  .lottery-table thead th:nth-child(4) {
    width: 15% !important;
  }
}

@media (max-width: 1280px) {
  table.table.table-striped tbody tr td {
    white-space: wrap;
    font-size: 0.7rem;
    word-break: break-all;
  }
  .customize-table thead tr th {
    white-space: unset !important;
    word-break: break-word;
  }
  .customize-table thead th:nth-child(5) {
    width: 14%;
  }
  .customize-table thead th:nth-child(6) {
    width: 22%;
  }
  .customize-table,
  .lottery-table {
    width: 1000px;
  }
  .charity-table {
    width: 1000px;
  }
  .lottery-table thead th:nth-child(4) {
    width: 15% !important;
  }
  .charity-table thead th:nth-child(6) {
    width: 14%;
  }
}
@media only screen and (max-width: 1097px) {
  .customize-table thead th:nth-child(5) {
    width: 17%;
  }
  .customize-table thead th:nth-child(6) {
    width: 26%;
  }
}
@media only screen and (max-width: 1025px) {
  .charity-table thead th:nth-child(6) {
    width: 13% !important;
  }
  .end-btn-cstm button {
    width: 100%;
    max-width: 97%;
    margin: 0;
  }
  .google-pay-button-container button.gpay-button.black.donate.en.hover {
    width: 100%;
    margin: 0;
    max-width: 97%;
  }
  .custom-height table thead th:nth-child(5) {
    width: 16%;
  }
  .custom-height table thead th:nth-child(6) {
    width: 25%;
  }
}

@media only screen and (max-width: 1050px) {
  .recharts-wrapper {
    width: 100% !important;
  }
  .recharts-wrapper .recharts-legend-wrapper {
    width: 100% !important;
  }
  .recharts-wrapper svg.recharts-surface {
    width: 100% !important;
  }
  .col-custom1024 {
    width: 100% !important;
  }
  .view-qr {
    margin: 0 !important;
  }
  #qr {
    display: flex;
    align-items: center;
  }

  nav.navbar.navbar-expand-sm.navbar-light.bg-dark.justify-content-between
    .profile {
    width: 70%;
    display: flex;
    justify-content: end;
  }
  nav.navbar.navbar-expand-sm.navbar-light.bg-dark.justify-content-between
    .responsive-toggle {
    width: 10%;
  }
  nav.navbar.navbar-expand-sm.navbar-light.bg-dark.justify-content-between a {
    width: 20%;
  }
}
body {
  min-height: 100%;
}
html {
  min-height: 100%;
}
footer.text-center.text-lg-start.text-dark {
  z-index: 4;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.height-100vh {
  height: 100vh;
}
.center-form-custom .custom-for-all {
  width: 80%;
  margin: auto;
}
.center-form-custom .custom-for-all form.p-3 {
  width: 100%;
}

.center-form-custom .custom-for-all form.LoginForm span.links_name {
  text-align: right;
  display: block;
  padding: 0px 15px;
}
#reports-li {
  cursor: pointer;
}
.card-custom {
  min-height: 100vh;
  display: grid;
  place-content: center;
}
.inner-card {
  width: 100%;
  max-width: 450px;
  margin: 0px 15px;
}
.inner-card-invalidQr{
  width: 100%;
  max-width: 800px;
  margin: 0px 15px;
}
@media only screen and (max-width: 768px) {
  .inner-card {
    width: 100%;
    max-width: 90%;
    /* margin: 0px 15px; */
    margin: auto;
  }
  .inner-card-invalidQr{
    width: 100%;
    max-width: 90% !important;
    margin: 0px auto !important;
 }
 .inner-card-invalidQr .LoginForm.custom-for-all .custom-card {
  padding: 45px 15px;
}
  .donationSummaryModal button.btn.btn-primary {
    float: right !important;
  }
  .view-qr {
    margin: 0 !important;
  }
  #qr {
    display: flex;
    align-items: center;
  }
  .customize-table thead th:nth-child(5) {
    width: 14%;
  }
  .customize-table thead th:nth-child(6) {
    width: 18%;
  }
}
.retailerContainer .ModalBackground .modelAddLocationContainer {
  /* max-width: 1200px !important; */
  max-width: 1900px !important;
  width: 100%;
  margin: 12px;
  max-height: 600px;
  overflow-y: scroll;
}
.retailerContainer
  .ModalBackground
  .modelAddLocationContainer
  .bodycustmscroll {
  /* max-width: 1200px !important; */
  max-height: 600px;
  overflow-y: scroll;
}
.retailerContainer .ModalBackground .modelLocationContainer {
  max-width: 1540px !important;
  width: 100%;
  margin: 12px;
}
.retailerContainer .modelAddLocationContainer .container {
  max-width: 1602px !important;
}
.MainDates label.col-form-label {
  margin: 0 0 0 8px;
}

@media (max-width: 767px) {
  .home-section {
    padding-bottom: 60px;
  }
  .footer-fixed-bottom {
    position: absolute !important;
  }
  .donationSummaryModal button.btn.btn-primary {
    float: right !important;
  }
  table.table.table-striped tbody tr td {
    white-space: nowrap;
    font-size: 0.7rem;
    word-break: break-all;
  }
  .customize-table.table.table-striped tbody tr td:last-child {
    display: flex;
  }
}
.bsgpsK div:first-child {
  white-space: inherit !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.customize-table.table.table-striped tbody tr td:last-child {
  display: flex;
}
.ldeolY div:first-child {
  white-space: inherit !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view-qr {
  margin: 0 !important;
}
#qr {
  display: flex;
  align-items: center;
}

.location-custom-height {
  max-height: 550px !important;
  overflow: auto;
}

.rdt_TableCol {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.rdt_TableRow {
  padding: 0.75rem;
  font-size: 14px !important;
}

.header-profilepic {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.charityContainer .ModalBackground .modalImportContainer {
  max-width: 506px !important;
  width: 100%;
}

.custom-file-import {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.retailerContainer .ModalBackground .RetailerPopupContainer {
  max-width: 778px !important;
  margin: 12px;
}

.radioInput {
  position: absolute;
  left: 90px;
  top: 21px;
}
.radioInputLottery {
  position: absolute;
  left: 165px;
  top: 21px;
}
.radioInputBoth {
  position: absolute;
  left: 265px;
  top: 21px;
}
.radioLable {
  position: absolute;
  top: 29px;
  left: 35px;
}
.radioLableLocation {
  position: absolute;
  top: 18px;
  left: 35px;
}
.radioLable2 {
  position: absolute;
  left: 109px;
  top: 29px;
}
.radioLable2Location {
  position: absolute;
  left: 109px;
  top: 18px;
}
.radioLableLottery {
  position: absolute;
  left: 185px;
  top: 29px;
}
.radioLableLotteryLocation {
  position: absolute;
  left: 185px;
  top: 18px;
}
.radioLable3 {
  position: absolute;
  left: 285px;
  top: 29px;
}
.radioLable3Location {
  position: absolute;
  left: 285px;
  top: 18px;
}
#processFeeInput {
  margin-top: -9px !important;
}
.cstm_mrgn_zero{
  margin-right:0 !important;
}
.cstm_posi_chng input#processFeeInput {
  margin-right: 0 !important;
}
.langPosition {
  position: absolute !important ;
  top: -8px;
}
.radioButton {
  position: absolute;
  top: 21px;
  left: 15px !important;
}

.radioLanInput {
  position: absolute;
  left: 90px;
  top: 59px;
}
.radioLanInputAdd {
  position: absolute;
  left: 149px;
  top: 59px;
}
.radioLanLable {
  position: absolute;
  top: 55px;
  left: 35px;
  width: 104px;
}
.radioLanLable2 {
  position: absolute;
  left: 109px;
  top: 55px;
  width: 15px;
}
.radioLanLableAdd2 {
  position: absolute;
  left: 169px;
  top: 55px;
  width: 162px;
}
.langLanPosition {
  position: absolute !important ;
  top: 13px;
}
.radioLanButton {
  position: absolute;
  top: 59px;
  left: 15px !important;
}
.radioFrequencyInput {
  position: absolute;
  left: 144px;
  top: 21px;
}
.radioFrequencyLable {
  position: absolute;
  left: 164px;
  top: 29px;
}

@media (max-width: 991px) {
  .btnOther {
    display: inline !important;
  }
  .btnCard {
    display: inline !important;
  }
  .donationSummaryModal button.btn.btn-primary {
    float: right !important;
  }
  /* .view-qr{
    font-size: 9px !important;
  } */
  .view-qr {
    margin: 0 !important;
  }
  #qr {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1020px) {
  .btnOther {
    display: inline !important;
  }
  .btnCard {
    display: inline !important;
  }
  .donationSummaryModal button.btn.btn-primary {
    float: right !important;
  }
  .view-qr {
    margin: 0 !important;
    font-size: 14px !important;
  }
  #qr {
    display: flex;
    align-items: center;
  }
}

.btnCard {
  justify-content: center !important;
  background-color: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
  margin-right: 8px;
}
.container-fluid-form {
  height: 339px !important;
}
.container-fluid-form-location {
  height: 444px !important;
}

.formgrpcustom {
  min-height: 60px;
  min-width: 270px;
}
.formgrpcustom2 {
  height: 140px;
}
.pad-15 {
  padding: 15px;
}
.left-side {
  float: left;
}
.btnOther {
  justify-content: center !important;
  background-color: #17dad1 ;
  color: #fff ;
  border-color: #17dad1 ;
  margin-right: 8px;
}

.div-image {
  margin-top: -278px;
  z-index: 99;
}

.div-block {
  pointer-events: none;
  opacity: 0.4;
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999; */
}

/* 24-08-2022 */

.main-btn-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.main-btn-wrapper img.btnn-img {
  max-width: 50px;
  /* width: 10px; */
  width: 20px;
}
.model-inside-img {
  width: 100%;
  height: 100px;
  border-radius: 100%;
}
.main-btn-wrapper {
  display: flex;
  align-items: center;
  /* width: 28%; */
  width: 88%;
  background-color: #000;
  padding: 15px 15px;
  border-radius: 125px;
}
.main-btn-wrapper .text-heading {
  margin-left: 4px;
  /* font-size: 0.5rem; */
  font-size: 1.3rem;
  color: #fff;
  padding: 0px 10px;
  margin-top: -4px;
 
}
.main-btn-wrapper .img-portion {
  /* padding: 11px 14px; */
  padding: 32px 14px;
  background-color: #fff;
  /* width: 100px; */
  width: 104px;
  text-align: center;
  border-radius: 94px;
}
/* .img-portion-inside{
  width: 104px;
  text-align: center;
} */
.img-portion-inside {
  display: flex;
  align-items: center;
}
.img-portion-logo-circle {
  width: 109px;
  height: 106px;
  border-radius: 50%;
  background: #fff;
  /* margin-bottom: 16px; */
  display: flex;
  /* justify-content: center; */
}


.qrcodedetails .img-portion-logo-circle {
  width: 90px;
  height: 90px;
  margin-left: 20px;
  background: #fff;
  display: flex;
  border-radius: unset;

}

.img-portion-logo-circle img {
  width: 84%;
  padding: 7px;
  margin: auto;
  border-radius: 80px;
}
.google-pay-button-container {
  display: block;
}
.App.end-btn-cstm button {
  width: 100%;
  max-width: 240px;
  margin: 0px;
}

.thank-you-text {
  margin-top: 54px;
  margin-bottom: -40px;
}

.thank-you-text h6 {
  font-size: 14px;
    color: #000000;
    text-align: center;
    padding: 15px 0 0 0;
}

.max-height-scroll {
  max-height: 600px;
  overflow-y: scroll;
}

.nextButton-size {
  width: 68px;
}
.lotterymodalContainer {
  max-width: 640px !important;
}
.lottery-table thead th:nth-child(4) {
  width: 12%;
}
.ModalBackground .lotterymodalContainer {
  width: 80%;
  max-width: 640px;
  background: #fff;
  padding: 20px;
  position: relative;
}
.ModalBackground .lotterymodalContainer button.closeBtn {
  border: 0;
  font-size: 30px;
  background: transparent;
  position: absolute;
  right: 7px;
  top: 7px;
  line-height: 1;
}
.scrollDiv {
  max-height: 500px;
  overflow-y: scroll;
}


/* for print css */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    /* page-break-before: auto; */
  }
}

@page {
  size: auto;
  margin: 10mm;
}
table,
tr,
td {
  page-break-inside: unset !important;
}
/* for end print css */

/*MultiSelect Option CSS */
#multiselectContainerReact {
  margin-bottom: 54px;
  float: none;
  border: none !important;
}
.searchWrapper {
  border: none !important;
}
/* Overriding element hover */
.optionListContainer {
  overflow: inherit;
}
.optionContainer li:hover,
.optionContainer .highlight {
  /* background: red; */
  color: #fff;
}
/* Ends overriding */

/* Placehoolder Color Change */
/* .searchBox#css_custom_input::placeholder {
  color: red;
}  */
.multicharityslector {
  overflow: inherit !important;
  max-height: 100% !important;
  margin-bottom: 19% !important;
}
#container-height {
  width: 60% !important;
  max-width: 60% !important;
}
/* .chip{
  display:none !important;
} */
/*MultiSelect Option CSS */

.search_input {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

.card-radio {
  background: #fff;
  padding: 20px 15px;

  border-radius: 10px;
  margin: 10px 0 10px;
  min-height: 104px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}
.card-radio .formgrpcustom {
  margin-bottom: 10px;
}
.card-radio h5 {
  margin-bottom: 20px;
}
.search-wrapper.searchWrapper {
  max-height: 68px;
  overflow: auto;
  position: static;
  min-height: 0;
  padding: 0;
}
.multiSelectContainer input.searchBox {
  position: absolute;
  left: 0;
  bottom: -60px;
}
img.icon_down_dir {
  bottom: -44px;
  top: inherit;
}
.optionListContainer {
  margin-top: 54px !important;
}
.multiSelectContainer input {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

/* custom CSS for the update donation part */

.lotteryTic {
  margin-top: 10px;
}

.lottic {
  margin-left: -17px;
}

.lotteryFifty {
  margin-left: 12px;
  margin-top: 10px;
}

.fifty {
  margin-left: 6px;
}
#pie-chart-surface {
  width: 750 !important;
  height: 320 !important;
}

/* for data table */
.expandDataTable .rdt_TableBody .rdt_ExpanderRow {
  width: 100%;
  max-width: 94%;
  margin: 10px auto;
}
.expandDataTable .rdt_TableBody {
  background: #e9ecef;
}
.expandDataTable .rdt_TableBody .rdt_TableHeadRow {
  background: #fff !important;
}
.expandDataTable .rdt_TableBody .rdt_TableBody {
  background: #fff;
}
.expandDataTable .rdt_TableBody .rdt_TableBody .rdt_TableHeadRow {
  background: #e9ecef !important;
}
.rdt_ExpanderRow > div {
  max-height: 745px !important;
}
/* .dFefnK {
  max-height: 745px !important;
  
} */
/* for users icon */
span.resend-verification {
  border: 1px solid #001fff;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  color: #001fff;
  cursor: pointer;
  margin: 0 5px;
}
.cardDisplay {
  display: block;
}
.cardNotDisplay {
  display: none;
}
.loaderTop{
  margin-top :20%;
}
.loaderText{
  font-size:23px;
  font-weight: 600;
  margin-left:16px;
}
.tableloaderText{
  font-size:14px;
  /* font-weight: 600; */
  margin-left:16px;
}
/* .resend-verification-disable{
  disabled:true;

} */

/* ====25 jan==== */
/* #addwidth .subMenu {
  max-height: 185px;
  height: 185px;
  overflow: auto;
} */

.donationSummary
  .rdt_TableBody
  > div.rdt_ExpanderRow
  .rdt_TableHead
  .rdt_TableHeadRow
  div:last-child {
  flex-grow: 2;
}

.donationSummary
  .rdt_TableBody
  > div.rdt_ExpanderRow
  .rdt_TableBody
  > div
  div:last-child {
  flex-grow: 2;
}
.main-cell {
  background: #e9ecef !important;
  color: #495065;
  font-weight: 600;
}
.sub-cell {
  background-color: #fafafa;
}
.subplus-cell {
  background-color: #fff;
  color: #495065;
  font-weight: 600;
}
.subplplus-cell {
  background-color: #e9ecef !important;
  color: #495065;
  font-weight: 600;
}
#print-modal {
  margin: 10px;
  padding: 4px 20px;
}
@media print {
  body,
  html {
    background-color: #fff !important;
  }
  .main-cell {
    background: #e9ecef !important;
    color: #495065;
    font-weight: 600;
  }
  .sub-cell {
    background-color: #fafafa;
  }
  .subplus-cell {
    background-color: #fff;
    color: #495065;
    font-weight: 600;
  }
  .subplplus-cell {
    background-color: #e9ecef !important;
    color: #495065;
    font-weight: 600;
  }
}
.charityContainer .ModalBackground .modalContainer .labelcheck input,
.charityContainer .ModalBackground .modalContainer .labelcheck label {
  position: static;
  margin: 0;
}
.charityContainer .ModalBackground .modalContainer .labelcheck input {
  margin-right: 5px;
}
.labelFlex {
  display: flex;
  align-items: center;
}
.labelcheck {
  display: flex;
  align-items: center;
}
.labelFlex .labelcheck:first-child {
  margin-right: 15px;
}
.donationSection {
  display: flex;
  flex-wrap: wrap;
}
.donationSection .labelcheck {
  margin-right: 30px;
}
.charityContainer .ModalBackground .modalContainer .card-radio {
  padding: 10px;
}

.customFooter_Box {
  margin-bottom: 15px;
}

.lotteryFormBox .custom-formGroup label {
  word-break: break-word;
}

.p0 {
  padding: 0px;
}

.customForm_G {
  height: auto !important;
  width: auto !important;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  grid-gap: 7px;
  gap: 7px;
  margin-right: 25px;
}

.customForm_G input {
  position: static;
  height: auto !important;
}

.customForm_G label {
  position: static;
  margin: 0 !important;
}

@media (max-width: 1440px) {
  .charityContainer .ModalBackground .modalContainer .card-radio {
    padding: 15px 10px;
  }
  .charityContainer .ModalBackground .modalContainer .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* 17-04-2023 */
.ModalBackground .modalContainer form.bodycstmscroll {
  max-height: calc(100vh - 300px) !important;
  overflow: auto !important;
}
.flexcstminput1 {
  display: flex;
}
.flexcstminput1 input[type="text"] {
  margin: 0;
}
.ModalBackground .modalContainer.modalcontainercstm {
  width: 90% !important;
}

@media screen and (max-width: 991px) {
  .flex-inutadd {
    flex-wrap: wrap;
  }
  .flexcstminput1 {
    width: 33.333%;
    padding-bottom: 10px;
  }
  .modalFooter.text-right.mt-5.pt-3.modalfootercstm button.actioncstm {
    width: auto !important;
    height: auto;
    display: inline-block;
    float: none;
    padding: 8px 15px;
  }
}

@media screen and (max-width: 767px) {
  .flexcstminput1 {
    width: 50%;
    padding-bottom: 10px;
  }
  .flex-inutadd {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .flexcstminput1 .input-icon > i {
    transform: translate(0, -50%);
    top: 50%;
  }
  .qrcodecstm {
    width: 300px !important;
    height: 300px !important;
  }
 

  .main-btn-wrapper.qrcodedetails .text-heading {
    font-size: 0.85rem;
  }

  /*25-04-2023 Css Here*/
  .headingTop h2,
  .customHeadingTop h2 {
    font-size: 24px !important;
  }

  .customOther_MainBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .card-radio .formgrpcustom {
    margin-bottom: 10px;
  }

  .customForm_G {
    height: auto !important;
    width: auto !important;
    display: flex;
    align-items: center;
    gap: 7px;
    margin-right: 0px !important;
  }

  .customForm_G input {
    position: static;
    height: auto !important;
  }

  .customForm_G label {
    position: static;
    margin: 0 !important;
  }
}

@media screen and (max-width: 576px) {
  .p0 {
    padding: 0px 15px !important;
  }
}

@media only screen and (min-width: 321px) and (max-width: 425px) {
  .from_to_width {
    min-width: 78px;
  }
  .from_to_width_2 {
    min-width: 78px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 939px) {
  .from_to_width_2 {
    min-width: 102px;
  }
}
@media only screen and (min-width: 426px) and (max-width: 488px) {
  .from_to_width {
    min-width: 100px;
  }
  .from_to_width_2 {
    min-width: 100px;
  }
}

@media screen and (max-width: 407px) {
  /*25-04-2023 Css Here*/
  .headingTop h2,
  .customHeadingTop h2 {
    font-size: 20px !important;
  }

  .customOther_MainBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .customBTNOther {
    margin-bottom: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  /* .from_to_width {
    min-width: 100px;
  } */
}

@media screen and (max-width: 340px) {
  .qrcodecstm {
    width: 250px !important;
    height: 250px !important;
  }
  .main-btn-wrapper.qrcodedetails .text-heading {
    font-size: 0.75rem;
    white-space: unset !important;

  }
  .from_to_width {
    min-width: 50px;
  }
  .from_to_width_2 {
    min-width: 50px;
  }
}

/*----------updated----------*/

.btn_resp {
  min-width: 150px;
}

@media (min-width: 1601px) and (max-width: 1900px) {
  .recharts-wrapper {
    height: 500px !important;
  }
}
@media (min-width: 1367px) and (max-width: 1600px) {
  .recharts-wrapper {
    height: 500px !important;
  }
}
@media only screen and (max-width: 1366px) {
  .recharts-wrapper {
    height: 500px !important;
  }
}
@media only screen and (max-width: 1024px) {
  span.recharts-legend-item-text {
    font-size: 8px !important;
  }
  .charityContainer .ModalBackground .modalContainer {
    margin: 35px !important;
  }
  ul.recharts-default-legend {
    padding: 40px 0 20px 0 !important;
  }
  .col-custom1024.frst-col {
    margin-top: 80px;
    padding-right: 40px;
  }
  /* .ul.recharts-default-legend li {
  display: flex !important;
}
ul.recharts-default-legend li {
  width: auto;
  font-size: 12px;
}

svg#pie-chart-surface, svg.recharts-surface {
  width: 10px !IMPORTANT;
  height: 10px;
} */
}
@media only screen and (max-width: 992px) {
  span.recharts-legend-item-text {
    font-size: 12px !important;
  }

  .recharts-wrapper {
    height: 400px !important;
  }
  .modelAddLocationContainer.addLocation {
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .imgcard.Img {
    width: 25% !important;
  }
  .imgcard.Img + div {
    width: 75% !important;
  }
  .updatBtn {
    width: 180px !important;
    margin-left: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .max-height-scroll {
    max-height: 525px;
  }

  .customQRCode .qrcodecstm {
    width: 340px !important;
    height: 340px !important;
  }

  .customQRCode .main-btn-wrapper {
    width: 80%;
  }
  .cstmRetailer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
  .imgcard.Img {
    width: 70% !important;
  }
  .imgcard.Img + div {
    width: 100% !important;
    padding: 20px 0 !important;
  }
  .updatBtn {
    width: 180px !important;
    margin-left: auto !important;
  }
}

@media only screen and (max-width: 576px) {
  span.recharts-legend-item-text {
    font-size: 7px !important;
  }
  ul.recharts-default-legend {
    padding: 30px 0 0 0 !important;
  }
  .charityMain > div select {
    margin: 5px 0;
  }

  .modalFooter.modalfootercstm.cstmBtn > button.cstmBtn {
    width: auto !important;
    padding: 6px 20px;
    font-size: 12px;
  }

  .customQRCode {
    padding: 10px;
  }
  .topWrapper table > tr {
    display: flex;
    flex-direction: column;
  }
  .topWrapper table td,
  .topWrapper table tr {
    padding: 10px 0 !important;
  }
  .imgcard.Img > div {
    height: 140px !important;
    width: 120px !important;
  }
  .cstmRetailer table {
    font-size: 14px !important;
  }
  .updatBtn {
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .customQRCode .main-btn-wrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 420px) {
  .ModalBackground button.editMailBtn{
    font-size: 14px;
    padding: 5px 8px;
    margin-top: 9px !important;
  }
  
  button.editMailBtnLoc.change_posibtnsc-4 {
    font-size: 14px;
    padding: 5px 8px;
    margin-top: 10px !important;
  }
  ul.recharts-default-legend li {
    margin: 3px !important;
  }
  ul.recharts-default-legend {
    padding: 30px 0 0 0 !important;
  }
  .charityMain > div select {
    margin: 5px 0;
  }
  .modelAddLocationContainer.addLocation {
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modalFooter.modalfootercstm > button.cstmBtn {
    width: auto !important;
    padding: 5px 5px !important;
    font-size: 13px !important;
  }

 
  .customQRCode .main-btn-wrapper {
    width: 95%;
  }

  .max-height-scroll {
    max-height: 470px;
  }

  .customQRCode .qrcodecstm {
    width: 100% !important;
    height: 300px !important;
  }
  .cstmRetailer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
  .modalFooter:has(.updtBtn) {
    flex-direction: column;
  }
  .modalFooter:has(.updtBtn) button.updtBtn {
    width: 100% !important;
  }
}
@media only screen and (max-width: 350px) {
  .donationAction {
    display: flex;
    flex-direction: column;
  }

  .max-height-scroll {
    max-height: 425px;
  }

  .customQRCode .qrcodecstm {
    width: 100% !important;
    height: 249px !important;
  }

  .customQRCode .main-btn-wrapper {
    width: 100%;
  }
  .modalFooter.text-right.mt-5.pt-3 button{
    font-size: 12px;
  }
}

.sidebar.expendMenu .subMenu > ul li > a span {
  display: none !important;
}
.topWrapper button,
button.maxWidnone {
  width: auto;
  max-width: unset;
}
.charityMain {
  width: 50%;
  margin: 0;
  padding-right: 20px;
}
.charityMain .selectBox {
  width: 100%;
}
.avatarCstm {
  border: 2px solid #ddd;
  border-radius: 2px !important;
}

.cstmRetailer {
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}
.imgcard.Img {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgcard.Img + div {
  width: 80%;
  margin: 0;
  padding: 0 20px;
}
.imgcard.Img > div {
  height: 180px;
  width: 160px;
  background: #efefef;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  padding: 5px;
  border: 1px solid #ddd;
}
.topWrapper table td,
.topWrapper table tr {
  padding: 10px;
  white-space: unset;
  word-break: break-word;
}

/* custom for modal hide */
#modalpopup {
  z-index: -1 !important;
}

/* Css on 15/06/2023 starts */
.lotteryFifty-custom.lotteryFifty {
  margin-left: 0;
}

.row.row-gap {
  row-gap: 20px;
}

.row.row-gap .card-radio {
  height: 100%;
}

.custom-form-wrapper {
}
/* Css on 15/06/2023 ends */

@media only screen and (min-width: 240px) and (max-width: 319px) {
  .ModalBackground {
    height: 70%;
  }
  .charityContainer .ModalBackground .modalContainer {
    top: 27px;
    max-height: 296px;
    overflow: auto;
    min-width: 100%;
  }
}
/* .Cstm-card{
  min-height:480px;
} */

.input-align {
  margin-left: -1px;
  gap: 4px;
  width: 100%;
}

@media screen and (min-device-width: 913px) and (max-device-width: 1024px) {
  .text-size {
    font-size: 9px;
  }
  .custom-md {
    margin-left: 25px;
  }
}

@media (max-width: 1024px) {
  .charityContainer .ModalBackground .modalContainer .card-radio {
    padding: 15px 10px;
  }
}

@media (max-width: 425px) {
  .processing-input-icon > i {
    top: -26px;
  }
  .custom-sm {
    width: 20% !important;
  }
}

/*24-07-2023 Css Here*/
.customMain_container .ModalBackground .customModalContainer {
  height: auto;
  max-height: 100% !important;
  max-width: 95% !important;
}

.textNowWrap {
  text-wrap: nowrap;
}

.ModalBackground .wid100 {
  width: 95% !important;
  max-height: 100% !important;
  overflow-y: auto !important;
}

.m0_Input input {
  margin: 10px 10px 0px 0px !important;
}

/* .formgroupCustom .radioLanButton,  .formgroupCustom .radioLanInput {
    top: 33px;
  }

  .formgroupCustom .radioLanLable, .formgroupCustom .radioLanLable2 {
    top: 30px;
  } */

.wid100 form.bodycstmscroll {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}
.wid100 form.bodycstmscroll.adlocation_cstmbox {
  max-height: calc(100vh - 250px) !important;
}

form.bodycstmscroll .card-radio .form-group1.formgrpcustom.customForm_G {
  min-height: unset;
}
/*31-07-2023 Css Here*/
.adlocation_cstmbox {
  max-height: 100% !important;
  overflow: initial !important;
}

.retailerContainer .ModalBackground .cstm_editLoaction_modal {
  max-height: 100%;
  overflow: initial;
}

/* .retailerContainer .ModalBackground .cstm_editLoaction_modal form {
  max-height: 100%;
} */

.qrLogoBox .charity_imgbox {
  align-items: center;
}

.qrLogoBox .charity_imgbox img {
  width: auto;
  max-height: 90px;

}

@media screen and (min-width: 1500px) and (max-width: 1920px) {
 .retailerContainer .ModalBackground .cstm_editLoaction_modal form {
  max-height: 100%;
 }
}

@media screen and (min-width: 991px) and (max-width: 1100px) {
  .customMain_container .ModalBackground .customModalContainer .bodycstmscroll {
    overflow-y: auto;
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 991px) {
  .customMain_container .ModalBackground .customModalContainer .bodycstmscroll {
    overflow-y: auto;
    overflow-x: hidden !important;
  }
}

@media screen and (max-width: 400px) {
  .wid100 form.bodycstmscroll {
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }
}

@media screen and (max-width: 640px) and (orientation:landscape) {
  .adlocation_cstmbox {
    max-height: calc(100vh - 250px) !important;
    overflow-y: auto !important;
  }
}


/* 10 Aug */
.retailerContainer .ModalBackground .modalContainer.modelAddLocationContainer.cstm_editLoaction_Modal {
  height: auto;
  max-height: 100% !important;
  max-width: 95% !important;
}
.retailerContainer .ModalBackground .modalContainer.modelAddLocationContainer.cstm_editLoaction_Modal form {
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.cstm_posi_chng .Flex-input i.icon_pscstm {
  position: absolute;
  right: 10px;
  top: 0;
  font-style: normal;
}
.cstm_posi_chng .Flex-input i.icon_pscstm.addtwocst{
  top:15px;
  right:20px;
}
.cstm_posi_chng .Flex-input {
  position: relative;
}
g.recharts-layer.recharts-pie-labels g text {
  font-size: 10px;
}
.retailerContainer .ModalBackground .modelLocationContainer .modalHeader.mb-5 h3,
.retailerContainer .ModalBackground .modelAddLocationContainer .modalHeader.mb-5 h3,
.retailerContainer .ModalBackground .RetailerPopupContainer .modalHeader.mb-5 h3 {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
}
.cstm_labelsdf label {
    display: flex;
    align-items: center;
    gap: 10px;
}
.cstm_labelsdf label input {
    height: unset !important;
}
.cstm_lblsdf {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.form-group1.cstm_labelsdf {
  width: 100%;
}
.cstmbtn_res {
  display: flex;
}
.cstmheighds{
  height:110px;
}
.cstmmrtp10 {
  margin-top: 10px;
}
/* 11-10-2023 new css  */
.custmPos{
  top: 30px;
  right: -4px;
}
.custmWidthEdit{
  max-width: fit-content !important;
}
.custmHeigh95{
  height: 95%;
}
.custmeditBtn {
  right:-4px;
}
/* 11-10-2023 new css end */
@media(max-width:992px){
  .modelAddLocationContainer.addLocation form.bodycstmscroll.adlocation_cstmbox .modalFooter {
    padding-bottom: 1rem;
  }
  .ModalBackground button.editMailBtn, button.editMailBtnLoc.change_posibtnsc-4 {
    max-width: fit-content;
  }
}
@media(max-width:767px){
  .resp-input-pdng input {
    padding-left: 20px !important;
  }
  .form-group1.formgrpcustom1.cstmresp_mng {
    margin-bottom: 30px;
  }
  .wid100 form.bodycstmscroll.adlocation_cstmbox {
    max-height: 100% !important;
}
}
@media(max-width: 319px){
  .customModalContainer .modalFooter,
  .cstm_editLoaction_Modal .modalFooter {
    flex-wrap: wrap;
  }
  .customModalContainer .modalFooter.text-right.mt-5.pt-3 button,
  .cstm_editLoaction_Modal .modalFooter.text-right.mt-5.pt-3 button{
    width: 100%;
  }
}
@media(max-width:352px){
  ul.recharts-default-legend {
    overflow-x: hidden;
  }
}
@media(max-width:560px){
  .cstm_lblsdf{
    flex-direction: column;
    gap:0;
  }
}
@media(max-width:390px){
  .ModalBackground button.editMailBtn{
    margin-right: 10px !important;
  }
  
  button.editMailBtnLoc.change_posibtnsc-4{
    margin-right: 10px !important;
  }
  .charityContainer .ModalBackground .modalContainer input {
    padding-right: 5px;
  }
  .inpcsbtdf input{
    padding-right:5px;
  }
  .inner-card-invalidQr .LoginForm.custom-for-all .logo.donationLogo_Box span h1 {
    font-size: 34px !important;
  }
  .card-radio > div {
    padding-left: 0;
    padding-right: 0;
  }
}
@media(max-width:340px){
  .retailerContainer .ModalBackground .modalContainer.modelAddLocationContainer.cstm_editLoaction_Modal form{
    overflow-x: hidden;
  }
  .ModalBackground .card-radio {
    padding: 20px 10px;
  }
}
@media(max-width:330px){
  .retailerContainer .ModalBackground .modalHeader.mb-5 {
    margin-bottom: 30px !important;
  }
  .modalFooter.text-right.mt-5.pt-3 {
    margin-top: 20px !important;
  }
  label {
    font-size: 12px;
  }
}
@media(max-width:310px){
  .inner-card-invalidQr .LoginForm.custom-for-all .logo.donationLogo_Box span h1 {
    font-size: 28px !important;
  }
}
.form-group1.formgrpcustom.customForm_G.labelcheck.min-0 {
  min-height: unset;
}
@media(max-width:1366px){
  .charityContainer .ModalBackground .modalContainer input{
    padding-left: 18px;
    font-size: 12px;
    padding-right: 6px;
  }
  .inpcsbtdf input{
    padding-left: 12px;
    font-size:16px;
  }
}
.inner-card.max-widthadded {
  max-width: 450px;
}
.radioLable2:after, .radioLable:after {
  display:none;
}
label.no-req-sign:after {
  display: none;
}
.cstmchangeflexlist{
  display: flex;
  gap:25px;
}
.cstmchangeflexlist .custom-file.overflow-hidden{
  flex:1;
  font-size: 12px;
  height: 37px;
}
.cstmliste-sideup{
  flex:0;
}
.cstmliste-sideup img{
  width:70px;
  margin-top: -30px;
}
.cstmchangeflexlist .custom-file.overflow-hidden .custom-file-input {
  height: 37px !important;
}
.cstmchangeflexlist .custom-file.overflow-hidden .custom-file-label{
  line-height: 22px;
  height: 37px !important;
}
.cstmchangeflexlist .custom-file.overflow-hidden .custom-file-label:after{
  height: unset;
  line-height: 24px;
}
.arrowstickshowssh{
  position: relative;
  width: 100%;
  display: block;
}
.arrowstickshowssh:after {
  content: "" !important;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 9px solid #495057;
  bottom: 19px;
  position: absolute;
  right: 15px;
  z-index: 1;
}
.arrowstickshowssh input{
  position: relative;
  -webkit-appearance: none;
  z-index: 9;
  background: transparent !important;
}
.arrowstickshowssh input::-webkit-calendar-picker-indicator {
  display: none !important;
}
form.bodycstmscroll.adlocation_cstmbox.cstmscrolledsds {
  max-height: calc(100vh - 300px) !important;
  overflow: auto !important;
}


/*13-10-2023 css here*/
.wid100 form.bodycstmscroll.adlocation_cstmbox.cstmAddLocationModal {
  max-height: 100% !important;
}