/*--- */
a:hover { cursor: pointer; }

/*  */

.Dashboard {
  position: relative;
  margin-top: 70px;
  background: #0a2558;
}

.invalid {
border: 1px solid red;
}

input[type=text], input[type=password] {
width: 100%;
padding: 12px 20px;
margin: 8px 0;
display: inline-block;
border: 1px solid #ccc;
box-sizing: border-box;
}
nav.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  height: 70px;
  z-index: 99;
}
.themeColor{
  background-color: #2ad294;
}
.loginSub {
color: white;
padding: 14px 20px;
margin: 8px 0;
border: none;
cursor: pointer;
width: 100%;
}

a:hover {
opacity: 0.8;
}
nav.navbar.navbar-expand-sm.navbar-light.bg-dark {
  justify-content: space-between;
}

.cancelbtn {
width: auto;
padding: 10px 18px;
background-color: #f44336;
color: #fff;
border: none;
}

.imgcontainer {
text-align: center;
margin: 24px 0 12px 0;
}

img.avatar {
width: 40%;
border-radius: 50%;
}

.Logincontainer {
padding: 16px;
}

span.psw {
  float: right;
  padding-top: 0px;
  cursor: pointer;
}
form.LoginForm {
  width: 100%;
  background: #ffffff;
  /* border-top: 4px solid #2ad294; */
  border-radius: 20px;
  
}

/*  Dashbaord CSS   */
/* Googlefont Poppins CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Poppins', sans-serif;

}
.sidebar {
  position: sticky;
  top: 70px;
  height: calc(100vh - 60px);
  width: 270px;
  background: #0A2558;
  transition: all 0.4s ease;
  overflow: auto;
  overflow-x: hidden;
}
.sidebar.active{
width: 60px;
}
.profile .dropdown i {
  font-size: 40px;
}
.sidebar .nav-links{
margin-top: 10px;
}
.sidebar .nav-links li{
position: relative;
list-style: none;
height: 50px;
}
.sidebar .nav-links li a{
height: 100%;
width: 100%;
display: flex;
align-items: center;
text-decoration: none;
transition: all 0.4s ease;
}
.sidebar .nav-links li a.active{
  /* background: #081D45; */
background: #0c2452;
}
.sidebar .nav-links li a:hover{
/* background: #081D45; */
background: #0c2452;
}
.sidebar .nav-links li i{
min-width: 60px;
text-align: center;
font-size: 18px;
color: #fff;
}
.sidebar .nav-links li a .links_name{
color: #fff;
font-size: 14px;
font-weight: 400;
white-space: nowrap;
padding-right: 5px;
}
.sidebar .nav-links .log_out{
position: absolute;
bottom: 0;
width: 100%;
}
.sidebar.sidebar.expendMenu .nav-links li a .links_name {
 opacity: 0;
 transition: all 0.5s ease;
}
.home-section{
position: relative;
background: #f5f5f5;
min-height: calc(100vh - 70px);
width: calc(100% - 60px);
left: 0;
transition: all 0.5s ease;
}
.sidebar.active ~ .home-section{
width: calc(100% - 60px);
left: 60px;
}
.home-section .home-content{
position: relative;
padding: 20px;
}
.ModalBackground {
  background: #00000096;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}
.ModalBackground1 {
  background: #00000096;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:999;
}

.ModalBackground .modalContainer {
  width: 100% !important;
  max-width: 720px;
  background: #fff;
  padding: 20px;
  position: relative;
}

.ModalBackground .modalContainer form {
  border: 0;
  max-height: 54vh;
  overflow: auto;
}
.ModalBackground .modalContainer .form2 {
  max-height: 80vh !important;
}
.ModalBackground .modalContainer button.closeBtn {
border: 0;
font-size: 30px;
background: transparent;
position: absolute;
right: 7px;
top: 7px;
line-height: 1;
}

.ModalBackground .modalContainer button.closeBtn:hover {
color: #f00;
}
.profile button.btn.dropdown-toggle {outline: none;box-shadow: none;cursor: pointer;}

.profile button.btn.dropdown-toggle img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}
.form-group label::after{
margin-bottom: 0;
font-weight: 500;
content:"*";
color:red;
}

.color-cls {
  color: red;
  font-size: 16px;
  position: absolute;
  top: 7px;
  font-weight: bold;
}
/* .form-group label :after{
content:"*";color:red;
} */
.SearchWrap i {
top: 50%;
right: 10px;
transform: translate(0, -50%);
}

.SearchWrap input.form-control {
width: 380px;

}
span.edit {
  border: 1px solid #4caf50;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  color: #4caf50;
  cursor: pointer;
  margin: 0 5px;
  line-height: 1.5rem;
}

span.delete {
  border: 1px solid #f44336;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  color: #f44336;
  cursor: pointer;
  margin: 0 5px;
  line-height: 1.5rem;
}
.modalHeader {
border-bottom: 1px solid #ddd;
}
.modalFooter{
border-top: 1px solid #ddd;
}
.header-logo img {
  max-width: 160px;
}
.topWrapper.new-display-class.d-flex.mb-2 {
  display: inline-block !important;
  width: 100%;
}
div#exampleModal .modal-dialog {
  max-width: 74em;
}

div#exampleModal2 .modal-dialog {
  max-width: 74em;
}
#treeView{display:block}
ul#treeView li {
  list-style: none;
  margin: 10px 0px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  float: left;
}
ul#treeView li input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border: 1px solid #dedede !important;
  vertical-align: top;
  margin-right: 10px;
  margin-top: 2px;
}
.custom-height {
    height: 550px;
    overflow: scroll;
}

.custom-height table {
    margin: 0px !important;
}

.custom-height table thead {
    position: sticky;
    top: -1px;
}
table.table.table-striped thead tr th {
    white-space: nowrap;
    
}

.btn.focus, .btn:focus {
    box-shadow: none !important;
}
button.btn {
    font-size: 14px;
    padding: 5px 8px;;
}

table.table.table-striped tbody tr td {
    white-space: wrap;
    
}

.responsive-toggle {
    display: none;
}
.responsive-toggle {
    cursor: pointer;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb
{
	background-color: #0a2558;
	width: 6px;
	height:6px;
}

.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
.table-responsive::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
	height:6px;
}
.table-responsive::-webkit-scrollbar-thumb
{
	background-color: #0a2558;
	width: 6px;
	height:6px;
}
.modalContainer {
    width: 100% !important;
    max-width: 506px !important;
    
}
.modalContainer1 {
    /* width: 100% !important; */
    /* max-width: 506px !important; */
    min-width: 20%;
    border-bottom: 1px solid #ddd;
}
div#exampleModal .modal-dialog button.btn.btn-primary {
    margin: 0px 5px;
}
.ModalBackground button.btn.btn-primary {
    /*float: left;*/
    margin: 10px 10px;
}
.responsive-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;
  position: absolute;
  left: 254px;
  top: 0px;
}
.responsive-toggle i {
    font-size: 20px;
    color: #fff;
}
.subMenu ul li a{
  padding-left: 1rem;
}
.subMenu ul li a i {
  min-width: 38px !important;
  font-size: 15px !important;
}
.subMenu ul li a .links_name{
  font-size: 13px !important;
}
.rdt_ExpanderRow .rdt_ExpanderRow > div{
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow: auto;
  min-height: 0px;
  max-height: 400px !important;
}
#reports-li {
  display: flex;
}
.twrap{
  white-space: nowrap;
}

/* Responsive Media Query */
@media (max-width:1680px) {
  .sidebar.expendMenu ~ .home-section {
    width: calc(100% - 60px);
}
}
@media (max-width: 1240px) {

.sidebar.active{
  width: 220px;
}
.home-section{
  width: calc(100% - 60px);
  /* left: 60px; */
}

.sidebar.active ~ .home-section{
  overflow: hidden;
  left: 220px;
}

}
@media (max-width: 1150px) {
.header-logo img {
    max-width: 120px;
}

}
@media (max-width: 991px) {
.responsive-toggle
{display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;margin-right: 10px;}
	.responsive-toggle i {
    font-size: 20px;
    color: #fff;
}
.sidebar.sidebar.expendMenu .nav-links li a .links_name {
  opacity: 1;
  transition: all 0.5s ease;
 }
.sidebar.expendMenu {
  width: 230px !important;
  position: fixed !important;
  top: 60px;
  height: 100%;
}
.sidebar.expendMenu ~ .home-section {
  width: calc(100% - 60px);
  left: 60px;
}
.sidebar {
   top: 0px;
}
.userAccount_option {
  display: flex;
    align-items: center;
}

.ModalBackground .modalContainer button.closeBtn {
    border: 0;
    font-size: 30px;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 21px;
    line-height: 1;
    width: auto !important;
}
.ModalBackground .lotterymodalContainer button.closeBtn {
  border: 0;
  font-size: 30px;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 21px;
  line-height: 1;
  width: auto !important;
}
.modalFooter.text-right.mt-5.pt-3 button {
    float: left;
    margin: 0px 5px;
    width: 46% ;
    max-width: 100%;
}
.modalHeader h3 {
    font-size: 24px;
    margin-bottom: 14px;
}
input.uplaodController {
    width: 100%;
}
.modalContainer {
    width: 66% !important;
}
.sidebar{
  width: 60px;
  position: fixed;
  z-index: 9;
  top:60px;
}
.home-section {
  width: calc(100% - 60px);
  left: 60px;
}
table.table.table-striped tbody tr td .btn-group {
    display: none;
}
span.delete {
    margin-bottom: 1px;
}
.btn {
    height: auto;
    width: 100%;
    box-shadow: none;
    max-width: 120px;
    display: block;
    margin: 0px 5px 10px;
}
}
@media (max-width: 700px) {
.topWrapper {
    flex-direction: column;
}

.topWrapper button {
        width: 100% !important;
    max-width: 100%;
    margin: 0px !important;
    height: auto;
    box-shadow: none;
}
.profile .dropdown i {
    font-size: 30px;
}
label.username {
    margin-left: 10px !important;
}
.dropdown-menu a {
    text-align: left !important;
    padding: 5px 10px;
    font-size: 14px;
    border-bottom: 1px solid #dedede;
}

.dropdown-menu {
    padding: 0;
}
.pageTitle {
    font-size: 1.5em;
}

}
@media (max-width: 550px) {
  .recharts-legend-wrapper{
    left: 3px !important;
  }
  .custom-tooltip{
    position: absolute;
  }

}
.drop-menu-1{
  right:0%;
  left: auto !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  margin-left: 10px;
  margin-bottom: 0;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.avatar {
  vertical-align: middle;
  width: 100px  !important;
  height: 100px;
  border-radius: 50%;
}
.topWrapper.new-display-class h3{
  font-size: 20px;
    font-weight: 400;
    margin-top: 1rem;
}
.loginlogo img{
  height:100px;
}
.card-1{
  background-color: #25a09b;
  height:100vh;
  display: flex;
  align-items: center;
}

.reportSub{
  display:none;
}
.chartWrapper {
  position: relative;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events:none;
}

.chartAreaWrapper {
  width: 600px;
  overflow-x: scroll;
}

.pathError{
  text-align: center;
  color: white;
  margin-top: 37px;
}

.charityMain{
  display: flex;
  margin-right: 15px;
  align-items: center;
}

.charityMain h5{margin: 0;}

.tophead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.selectBox{margin-left: 13px; height: 33px; border-color: black; border-width: 3px; border-radius: 5px;}
.tophead .btn.btn-primary{margin-right: 12px;}
.custm-print{padding: 5px 21px!important;}
.customdate {
  display: flex;
  justify-content: flex-end;
}
.customdate label{margin:0 12px}
.customdate input{
  border-color: black;
  border-width: 1px;
  border-radius: 4px;
  padding: 3px;
}

.customdate2 {
  display: flex;
}
.customdate2 label{margin:0 12px}
.customdate2 input{
  border-color: black;
  border-width: 1px;
  border-radius: 4px;
  padding: 3px;
}
.table-responsive.custom-height.custm-width {
  width: 50%;
  margin: 0 auto;
  margin-top: 25px;
}

#addwidth ul li span{color: #fff;}
.m12{
  margin-top: 12px;
}

@media (max-width: 700px) {
  .tophead .btn.btn-primary{margin-top: 12px;}
  .customdate{margin-bottom: 15px;}
  .mobmarg{margin-top: 15px;}
  .customdate label {
    min-width: 40px;
}

.table-responsive.custom-height.custm-width {
  width: 100%;}
  #printarea h5{font-size: 18px;}
  /* .reportonly{display: flex;} */
  #addwidth ul li.reportonly span{color: #fff; }
  .recharts-wrapper svg{width: 100%;}
}
.ui-selectBox {
  max-width: 180px;
}

@media (width:768px){
  #addwidth ul li span {
    color: #fff;
    padding-left: 8px;
}
}

@media (max-width:1746px) and (min-width:1200px) {
  .charity-table thead th:nth-child(6){
    width: 14%; 
  }
  .ui-center-col {
    position: absolute;
    top: 45px;
    right: 15px;
  }
  .ui-lefttop{
    flex: 0 0 56%;
    max-width: 56%;
  }

  .ui-righttop{
    flex: 0 0 44%;
    max-width: 44%;
  }
  .customize-table thead th:nth-child(5){
    width: 15%;
  }
  .customize-table thead th:nth-child(6){
    width: 22%;
  }
}
@media (max-width:1746px) and (min-width:1375px) {
  .charity-table thead tr th {
    font-size: 0.8rem !important;
  }
 
  .ui-right-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ui-left-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ui-lefttop{
    flex: 0 0 56%;
    max-width: 56%;
  }

  .ui-righttop{
    flex: 0 0 44%;
    max-width: 44%;
  }
}
@media (max-width:1460px) and (min-width:1200px) { 
  .ui-center-col {
    flex: 0 0 35%;
    max-width: 35%;
    text-align: right !important;
  }
  .ui-lefttop{
    flex: 0 0 56%;
    max-width: 56%;
  }

  .ui-righttop{
    flex: 0 0 44%;
    max-width: 44%;
  }
  /* #addwidth .subMenu {
    max-height: 154px;
    overflow: auto;
} */
/* span.delete{
  margin-top: 5px;
} */
}
@media (max-width:1374px) and (min-width:1200px) {
 
  .ui-right-col {
    flex: 0 0 44%;
    max-width: 44%;
  }
  .ui-left-col {
    flex: 0 0 56%;
    max-width: 56%;
  }

  .ui-lefttop{
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ui-righttop{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .customdate{justify-content: flex-start;}

  .ui-left-col .tophead div:nth-child(1) {
    margin-right: 5px;
  }  
  .ui-left-col .tophead div:nth-child(2) .btn.btn-primary {
    margin-right: 0px;
  }
  .ui-left-col .tophead div:nth-child(3) .btn.btn-primary {
    margin-right: 0px;
  }
 
  .ui-right-col .customdate div > label {
    margin-right: 5px;
  }  
  .ui-right-col .customdate div > label + input {
    max-width: 140px;
  } 
}
@media (max-width:1097px){
  .charity-table thead th:nth-child(6){
    width: 14%; 
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width:992px) {
  .formgrpcustom2 {
    height: 95px;
}
  #reports-li {
    display: unset;
}
#reports-li .subMenu ul li span{
  display: none;
}
#reports-li.expendMenu ul li span {
  display: inline-block;
}
  #addwidth.expendMenu ul li span {
    display: inline-block;
}
#addwidth ul li span {
  display: none;
}
  nav.navbar.navbar-expand-sm.navbar-light.bg-dark .profile {
    width: 81%;
    display: flex;
    justify-content: end;
}
nav.navbar.navbar-expand-sm.navbar-light.bg-dark .responsive-toggle {
  width: 10%;
}
nav.navbar.navbar-expand-sm.navbar-light.bg-dark a {
  width: 20%;
}
  .customdate {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
}
.tophead {
  justify-content: center;
}
.table-responsive.custom-height.custm-width {
  width: 100%;}
  /* .reportonly {
    display: flex;
} */
.tophead .btn.btn-primary {
  margin-right: 12px;
  margin-top: 11px;
}


.responsive-toggle
{display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;}
	.responsive-toggle i {
    font-size: 20px;
    color: #fff;
}
.ModalBackground .modalContainer button.closeBtn {
    border: 0;
    font-size: 30px;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 21px;
    line-height: 1;
    width: auto !important;
}
.modalFooter.text-right.mt-5.pt-3 button {
    margin: 0px 5px;
    width: auto !important;
    min-width: 60px;
    padding: 5px 20px;
}
.modalHeader h3 {
    font-size: 24px !important;
    margin-bottom: 14px;
}
input.uplaodController {
    width: 100%;
}
.modalContainer {
    width: 66% !important;
}
.sidebar {
  width: 60px;
  height: calc(100vh - 24px);
}
.home-section {
  width: calc(100% - 60px);
  left: 60px;
}
table.table.table-striped tbody tr td .btn-group {
    display: none;
}
span.delete {
 
}
.btn {
    height: auto;
    width: 100%;
    box-shadow: none;
    max-width: 160px;
    display: block;
    margin: 0px 5px 10px;
}

#addwidth ul li span {
  margin-left: 8px;
}
#addwidth ul li:last-child .fa-caret-down{
  margin-left: 0rem !important;
}

@media(max-height: 1024px){ 
}


}
@media only screen and (min-device-width: 320px) and (max-device-width:767px) {
  .formgrpcustom2 {
    height: 95px;
}
  .input-icon > i {
    transform: translate(0, 83%);
    top: 83%;
}
  #reports-li {
    display: flow-root;
    margin-top: 1rem;
    height: 32px;
}
.charityContainer .ModalBackground .modalContainer {

  width: 93% !important;
  padding: 14px !important;
 
}
.subMenu ul li {
  height: 40px !important;
}
#reports-li.subMenu ul li span{
  display: none;
}
#reports-li.expendMenu ul li span {
  display: inline-block;
}
.retailerContainer.p-3{padding:0!important}
.pageTitle {
  font-size: 1.3em;
}
.custm-print{padding: 5px 40px!important;}
.tophead {
  display: initial;
  justify-content: center;}
  .customdate{margin-top: 15px;}
  .customdate input{width: 75%;}
  .ui-selectBox {
    max-width: 100%;
}
.selectBox {
   margin-left: 0;
  width: 100%;}
  .charityMain {
    display: initial;
    width: 100%;
}

  .charityMain h5{margin-bottom:15px!important; margin-top: 15px;}

  .flmob{float:left}
  .col-xl-3.col-lg-12.text-center.ui-center-col {
    text-align: left!important;
}
.customdate label {
  padding-left: 0;
  margin-left: 0;
}
.tophead .btn.btn-primary {
  margin: 0;
  margin-right: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}
nav.navbar.navbar-expand-sm.navbar-light.bg-dark.justify-content-between .profile {
  width: 70%;
  display: flex;
  justify-content: end;
}
nav.navbar.navbar-expand-sm.navbar-light.bg-dark.justify-content-between .responsive-toggle {
  width: 10%;
}
nav.navbar.navbar-expand-sm.navbar-light.bg-dark.justify-content-between a {
  width: 20%;
}
#addwidth .subMenu {
  max-height: initial;
  overflow: inherit;
}
#addwidth ul li span {
  display: none !important;
}
.subMenu ul li span{
  display: block !important;
}
#addwidth.expendMenu ul li span {
  display: inline-block !important;
}
.userAccount_option{
  margin-right: 10px;
}
#reports-li span .fa-caret-down{
  position: absolute;
  left: 0;
}
}

.google-pay-button-container.not-ready {
  width: unset !important;
  height: unset !important; 
  overflow: unset !important;
}
 
.sidebar.expendMenu {
  width: 60px;
  position: sticky;
  z-index: 9;
}

p.Spacing-custom {
  margin: 0;
  position: relative;
  top: 25px;
  text-align: center;
}
/* @media (width: 1280px){
  #addwidth .subMenu {
    max-height: 112px;
    overflow: auto;
}
} */
/* @media(max-height: 820px){
  #addwidth .subMenu {
    max-height: 120px;
    overflow: auto;
    display: inline-block;
}
} */

.expendMenu .subMenu {
  width: 100%;
  display: inline-block;
}

/*27-04-2023 CSS Here*/
.donationLogo_Box .charity_logo {
  align-items: initial;
  overflow: hidden;
  border: 4px solid #fff;
  border-radius: 4px;
  align-items: center;
  width: 90px;
  height: 90px;
}

.donationLogo_Box .charity_logo img {
  width: auto;
  max-width: 100%;
  padding: 0;
  max-height: 90px;
}

.qrLogoBox .img-portion-logo-circle {
  overflow: hidden;
  justify-content: center;
  border: 4px solid #fff;
}

.qrLogoBox .img-portion-logo-circle img {
   width: auto; 
  max-width: 100%;
  margin: initial;
  border-radius: 0px;
  padding: 0;
}

#transaction-id:focus option:first-of-type {
  display: none;
}

@media(width: 1024px)
{

  .nowrapTD {
    white-space: nowrap;
}

  /* .sidebar.expendMenu {
  width: 230px;
} */
.sidebar.expendMenu .nav-links li a .links_name {
  opacity: 1 !important;
}

}

@media (max-width:480px) {
  .responsive-toggle {
    left: 155px;
  }
  .header-logo{
    margin-bottom: 0.8rem;
  }
}




/*13-10-2023 Css Here*/
.lotteryPage {
  width: 100%;
  max-width: calc(100% - 240px);
}
/* 12-11-2023 new css  */
.custm-mb1{
  margin-bottom: 1rem;
}
.custmHeight{
  min-height: 60px;
}
.updtRadioBtn{
  top: 32px;
}
.updtRadioBtnTxt{
  top: 29px;
}
.crosPost{
  position: absolute;
  top: -10px;
  right:-4px
}
.custmImgPos img
{
margin: 0;
}
@media(min-width:992px){
.addradioRetl{
  top: 45px;
}
.addradioRetlTxt{
  top:42px;
}
}

@media(max-width:576px){
.custmMt-1{
  margin-top:10px;
}
.custmMob-1{
  margin-bottom: 26px;
}
/* .cstmheighds {
  height: 90px;
} */
.addradioRetl{
  top: 45px;
}
.addradioRetlTxt{
  top:42px;
}
}

@media screen and (max-width:384px) {
  .maxwidth100 {
    max-width: 100%;
  }
}